<script setup>
onMounted(() => {
  const container = document.getElementById('curator-feed-default-feed-layout');
  if (container) {
    const script = document.createElement('script');
    script.async = true;
    script.charset = 'UTF-8';
    script.src = 'https://cdn.curator.io/published/b71e90f3-13dc-4185-9b30-7680ac8079fc.js';
    // Připojíme skript přímo do kontejneru, případně jej můžeme připojit do document.body
    container.appendChild(script);
  }
});
</script>

<template>
  <div id="curator-feed-default-feed-layout" class="bg-black"></div>
  <!-- <a href="https://curator.io" target="_blank" class="crt-logo crt-tag"
    >Powered by Curator.io</a
  > -->
</template>

<style scoped>
h2 {
  margin-bottom: 0rem;
  margin-top: 3rem;
}

.crt-widget.crt-widget-branded {
  /* background-color: black;
  padding-bottom: 0; */
  /* border-bottom: white 2px solid; */
}
</style>
